<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 v-if="isEquel">변경된 큐레이션으로 음악이 재생됩니다.</h3>
            <h3 v-else>해당 스페이스로 재생하시겠습니까?</h3>
            <div class="single-file-check" v-if="isEquel">
                <p @click="linkPlayer()">확인</p>
            </div>
            <div class="single-file-btn" v-else>
                <p @click="linkMySpace()">아니오</p>
                <p id="color-text" @click="linkPlayer()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueCookies from 'vue-cookies';
import router from '@/router';
import { leaveChannel } from '@/assets/js/webcast';

export default defineComponent({
  name: 'CurationUpdated',
  data () {
    return {
      isEquel: false
    };
  },
  props: {
    spaceId: String,
    spaceNm: String,
    spaceType: String,
    findChannel: String
  },
  methods: {
    async linkPlayer () {
      // channelId가 있을때
      if (this.$store.state.channelId !== '') await leaveChannel();
      if (this.$store.state.isBrandMusic) {
        // 브랜드 음원 값이 true인 경우
        this.$store.commit('setIsBrandMusicPlay', false); // 브랜드 음원의 재생 값 false
        this.$store.commit('setIsBrandMusic', false); // 브랜드 음원 값 false
        const video = document.getElementById('brand_video');
        video.pause();
      }
      VueCookies.set('spaceId', this.spaceId);
      this.$store.commit('setSpaceNm', this.spaceNm);
      this.$store.commit('setSpaceId', '');
      this.$store.commit('setMute', false);
      this.$store.commit('setFindChannel', this.findChannel);
      if (this.$store.state.division === 'Building') {
        var updateCurationForBuilding = {
          spaceId: this.spaceId,
          isChange: true
        };
        this.$store.commit('setUpdateCurationForBuilding', updateCurationForBuilding);
      }
      this.$VideoPlayer.onPause();
      this.$emit('closePlay');
      const path = localStorage.getItem('seongdongpath');
      if (path) {
        this.$router.push({ name: path, params: { spaceState: 'changeSpace' } }).catch(() => {});
        localStorage.removeItem('seongdongpath');
      } else {
        this.$router.push({ name: 'PlayerView', params: { curUpdate: 'updated', setIsLoading: true } }).catch(() => {});
        // this.$router.push({ name: "PlayerWS", params: { curUpdate: 'updated' } });
      }
    },
    linkMySpace () {
      this.$emit('closePlay');
      router.push({ name: 'MySpace', params: { curUpdate: 'updated' } });
    }
  },
  mounted () {
    if (VueCookies.get('spaceId') != null && this.spaceId === VueCookies.get('spaceId')) {
      this.isEquel = true;
    } else if (this.spaceType === 'Building') {
      this.isEquel = true;
    } else {
      this.isEquel = false;
    }
  }
});
</script>
