<template>
    <div class="wrap">
        <Play
            :spaceId="spaceInfo.spaceId"
            :spaceNm="spaceInfo.spaceNm"
            :spaceType="spaceInfo.spaceType"
            v-if="ShowPlay"
            @closePlay="ShowPlay = false"
        />
        <div class="space_pc">
            <section class="main space4_4 space4_5">
                <!-- 로딩중 -->
                <div class="main_box" v-if="lodingStatus">
                    <h3 v-if="spaceInfo.sector != null">
                        <span v-html="spaceInfo.spaceNm" />에<br />
                        어울리는 음악을 선곡 중입니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span> 중인<br />
                        <span>{{ carCompleteCuration2 }}</span
                        >에<br />
                        어울리는 음악을 선곡 중입니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper updatestore5">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                        <button class="opacity cursor_init">다음</button>
                    </div>
                </div>
                <!-- 로딩 완료 -->
                <div class="main_box" v-else>
                    <h3 v-if="spaceInfo.sector != null">
                        <span v-html="spaceInfo.spaceNm" />에<br />
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span
                        >중인 <br />
                        <span>{{ carCompleteCuration2 }}</span
                        >에<br />
                        어울리는 음악이 선곡 되었습니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll">
                            <div class="meet_boll1"><img src="/media/img/space_4_check.svg" alt="" /></div>
                            <img class="meet_boll2" src="/media/img/space_loding_img2.png" alt="" />
                        </div>
                        <button @click="linkPlayer()">다음</button>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 로딩중 -->
        <div class="space_mo h_100" v-if="lodingStatus">
            <section class="main space4_4">
                <div class="main_box">
                    <h3 v-if="spaceInfo.sector != null">
                        <span v-html="spaceInfo.spaceNm" />에<br />
                        어울리는 음악을 선곡 중입니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span> 중인<br />
                        <span>{{ carCompleteCuration2 }}</span
                        >에<br />
                        어울리는 음악을 선곡 중입니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <button class="opacity3">다음</button>
            </div>
        </div>
        <!-- 모바일 로딩 완료 -->
        <div class="space_mo h_100" v-else>
            <section class="main space4_4 space4_5">
                <div class="main_box">
                    <h3 v-if="spaceInfo.sector != null">
                        <span v-html="spaceInfo.spaceNm" />에<br />
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ carCompleteCuration1 }}</span> 중인<br />
                        <span>{{ carCompleteCuration2 }}</span
                        >에<br />
                        어울리는 음악이 선곡 되었습니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll">
                            <div><img src="/media/img/space_4_check.svg" alt="" /></div>
                            <img src="/media/img/space_loding_img2.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <button @click="linkPlayer()">다음</button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import { updateCurationNotice } from '@/service/api/menuApi';
import { postRandomPreferMusic } from '@/service/api/profileApi';
import Play from '@/components/modal/Space/CurationUpdated.vue';

export default defineComponent({
  name: 'FinishedCuration',
  data () {
    return {
      spaceInfo: {},
      lodingStatus: true,
      ShowPlay: false
    };
  },
  components: {
    Play
  },
  async created () {
    this.lodingStatus = true;

    const noticeTypeDetail = this.$cookies.get('userId');
    const aToken = this.$cookies.get('aToken');
    const headers = { 'X-AUTH-TOKEN': aToken };
    await this.getCurationTemp();
    if (Object.keys(this.$route.params).length > 0) {
      const { status, genre } = this.$route.params;
      const spaceId = status === 'updateCuration' ? this.$cookies.get('spaceId') : localStorage.getItem('spaceId');
      if (status === 'updateCuration' || status === 'updateCurationMyspace') {
        const { data: preferMusicData } = await postRandomPreferMusic(spaceId);
        const { resultCd: preferMusicResult } = preferMusicData;
        if (preferMusicResult === '0000') {
          const { data: noticeTypeData } = await updateCurationNotice(spaceId, noticeTypeDetail, headers);
          const { resultCd: noticeTypeResultCd } = noticeTypeData;
          if (noticeTypeResultCd === '0000') {
            if (genre === '13') {
              this.$router.push({ name: 'MySpaceUpdateSelectChannel' });
              return false;
            }
            this.lodingStatus = false;
          } else {
            this.lodingStatus = false;
          }
        } else {
          alert('선호음악 선정에 실패하였습니다.');
        }
      }
    } else {
      this.lodingStatus = false;
    }
  },
  methods: {
    linkPlayer () {
      if (this.$store.state.installablePlayer) {
        this.$router.push({ name: 'InstallablePlayerUpdateCuration' });
      } else {
        this.ShowPlay = true;
      }
    },
    async getCurationTemp () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          this.spaceInfo = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loding () {
      this.lodingStatus = false;
    }
  },
  mounted () {},
  computed: {
    carCompleteCuration1 () {
      if (Object.keys(this.spaceInfo).length > 0) {
        return this.spaceInfo.carPurpose.length > 1
          ? `${this.spaceInfo.carPurpose[0]}+${this.spaceInfo.carPurpose.length - 1}`
          : this.spaceInfo.carPurpose[0];
      }
      return '';
    },
    carCompleteCuration2 () {
      return `${this.spaceInfo.spaceNm}`;
    },
    isSeondong () {
      const route = this.$cookies.get('route');
      return route !== 'seongdong';
    }
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped>
.meet_boll {
  margin-top: 140px !important;
  margin-bottom: 50px !important;
  height: 146px;
}

@media (max-width: 1206px) {
  .main.space4_4 {
    padding-top: 120px;
  }

  .space4_4 h3 {
    font-size: 32px;
  }

  .meet_boll {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .wrapper.updatestore5 {
    margin-top: 113px !important;
  }

  .meet_boll2,
  .meet_boll1 {
    width: 160px;
    height: 160px;
  }

  .loading_ing button {
    font-weight: 700;
  }
}
</style>
